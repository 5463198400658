//React Hooks
import { useEffect, useState } from "react";

//CSS + Bootstrap
import "./Navigation.scss";
import { Offcanvas } from "react-bootstrap";

//GSAP
import { gsap } from "gsap";

//Misc
import { Link } from "react-scroll";
import Hamburger from "hamburger-react";
import Logo from "../Home/Logo";

export default function Navigation() {
  const [show, setShow] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const handleClose = () => {
    setShow(false);
    setOpen(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    gsap.from(".hamburger-react", {
      delay: 1.25,
      y: "-150vw",
      ease: "power2.out",
      duration: 2,
    });
  }, []);

  return (
    <div>
      <Hamburger
        toggled={isOpen}
        toggle={setOpen}
        onToggle={handleShow}
        size={window.innerWidth / 8}
      />
      <Offcanvas show={show} onHide={handleClose} placement="start">
        <Offcanvas.Header>
          <Logo />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="linkList">
            <Link to="home" offset={0} onClick={handleClose}>
              Startseite
            </Link>
            <Link to="laden" offset={0} onClick={handleClose}>
              Unser Geschäft
            </Link>
            <Link to="aboutMe" offset={0} onClick={handleClose}>
              Über Mich
            </Link>
            <Link to="versand" offset={0} onClick={handleClose}>
              Leistungen
            </Link>
            <Link
              to="versand"
              className="untergruppe"
              offset={0}
              onClick={handleClose}
            >
              Versandservice
            </Link>
            <Link
              to="schuhrepair"
              className="untergruppe"
              offset={0}
              onClick={handleClose}
            >
              Schuhreparaturen
            </Link>
            <Link
              to="reitstiefel"
              className="untergruppe"
              offset={0}
              onClick={handleClose}
            >
              Reitsportartikel
            </Link>
            <Link
              to="lederarbeit"
              className="untergruppe"
              offset={0}
              onClick={handleClose}
            >
              Lederarbeiten
            </Link>
            <Link
              to="keys"
              className="untergruppe"
              offset={0}
              onClick={handleClose}
            >
              Schlüssel
            </Link>

            <Link to="produkte" offset={0} onClick={handleClose}>
              Produkte
            </Link>
            <Link
              to="schuhpflege"
              className="untergruppe"
              offset={0}
              onClick={handleClose}
            >
              Schuhpflege
            </Link>
            <Link
              to="belt"
              className="untergruppe"
              offset={0}
              onClick={handleClose}
            >
              Gürtel & Schnallen
            </Link>
            <Link to="kontakt" offset={0} onClick={handleClose}>
              Kontakt
            </Link>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
