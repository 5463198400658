import "../App.scss";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import img from "../assets/laden.jpeg";

export default function Location() {
  const routeButtonHandler = () => {
    window.open("https://goo.gl/maps/1F9SM15pofsUBUvc6");
  };

  return (
    <Card id="laden">
      <Card.Img
        variant="top"
        src={img}
        style={{ objectPosition: "bottom", objectFit: "cover" }}
      />
      <Card.Body>
        <Card.Title style={{ textAlign: "center" }}>
          Willkommen in der <br />
          Schuhmacherei Vella!
        </Card.Title>
        <div>
          <h2 style={{ textAlign: "center" }}>Schön, dass Sie da sind!</h2>
          Wir sind ein Schuhmacher Fachbetrieb mit über 30 Jahren
          Berufserfahrung. Unsere Schuhmacherei befindet sich seit 20 Jahren in
          der Dietzenbacher Altstadt.
          <Button
            variant="secondary"
            onClick={routeButtonHandler}
            style={{ display: "flex", margin: "15px auto" }}
          >
            In Google Maps anzeigen
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}
