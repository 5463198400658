import "../App.scss";
import Card from "react-bootstrap/Card";
import img from "../assets/belt/belt1.jpeg";

export default function Belt() {
  return (
    <Card id="belt">
      <Card.Img variant="top" src={img} style={{ objectPosition: "top" }} />
      <Card.Body>
        <Card.Title>Gürtel & Schnallen</Card.Title>
        <Card.Text>
          Wir bieten hochwertige Ledergürtel an, welche vor Ort Individuell
          angepasst werden. Unter anderem finden Sie bei uns auch eine große
          Auswahl an ausgefallenen Gürtelschnallen.
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
