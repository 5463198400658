import { useEffect } from "react";
import "./Home.scss";
import Logo from "./Logo";

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Home() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Aufbau der Startseite
    const initialTimeLine = gsap.timeline();

    initialTimeLine
      .from(".homeContainer", {
        opacity: 0,
        duration: 2,
        ease: "power3.in",
      })
      .from(".logoContainer", {
        y: "15",
        opacity: 0,
        ease: "expo",
        duration: 2,
      });
  }, []);
  return (
    <>
      <div id="home" className="homeContainer">
        <Logo />
      </div>
      {/* <div className="text homeTitle">
        <h1>
          Willkommen in der
          <br /> Schuhmacherei Vella!
        </h1>
      </div> */}
    </>
  );
}
