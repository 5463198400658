import { useState } from "react";
import "../App.scss";

import { Alert, Button, Card, Modal } from "react-bootstrap";

import img from "../assets/delivery.jpg";

import formImg from "../assets/versand/form.jpg";
import packageImg from "../assets/versand/package.jpg";
import phoneImg from "../assets/versand/phone.jpg";
import workImg from "../assets/versand/work.jpeg";
import paymentImg from "../assets/versand/payment.jpg";
import returnImg from "../assets/versand/return.jpg";
import Preisliste from "./Preisliste";
import Formular from "../assets/Reparaturformular.pdf";

export default function Versand() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose} className="versandModal">
        <Modal.Header closeButton>
          <Modal.Title>Versandservice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Img variant="top" src={formImg} />
            <Card.Body>
              <Card.Title>Reparaturauftrag ausfüllen</Card.Title>
              <div className="h1">
                Drucken Sie das Reparaturformular aus und füllen Sie es{" "}
                <i>vollständig</i> aus.
              </div>
              <Button variant="secondary" href={Formular}>
                Zum Formular
              </Button>
            </Card.Body>
          </Card>

          <Card>
            <Card.Img variant="top" src={packageImg} />
            <Card.Body>
              <Card.Title>Schuhe und Formular an uns senden</Card.Title>
              <div className="h1">
                Das Formular mit den Schuhen - <i>bitte immer paarweise</i> - an
                uns versenden.
              </div>

              <div className="h2">Adresse</div>
              <div className="h3">Schuhmacherei Vella</div>
              <div className="h3">Rathenaustraße 20</div>
              <div className="h3">63128 Dietzenbach</div>
            </Card.Body>
          </Card>

          <Card>
            <Card.Img variant="top" src={phoneImg} />
            <Card.Body>
              <Card.Title>Ganz nach Ihren Wünschen</Card.Title>
              <div className="h1">
                Sobald die Schuhe bei uns eingetroffen sind werden wir uns bei
                Ihnen melden, um die gewünschten Arbeiten zu besprechen und
                Ihnen ein Kostenvoranschlag zu unterbreiten.
              </div>
            </Card.Body>
          </Card>

          <Preisliste />

          <Alert variant="danger">
            Sollte keine Reparatur zustande kommen, könne wir Ihre Schuhe
            kostenpflichtig zurücksenden, oder kostenlos entsorgen.
          </Alert>

          <Card>
            <Card.Img variant="top" src={workImg} />
            <Card.Body>
              <Card.Title>Ran an die Arbeit</Card.Title>
              <div className="h1">
                Die gewünschten Arbeiten werden erledigt. Sollten wir
                nachträglche Vorschläge haben melden wir uns selbstverständlich
                bei Ihnen, um diese zu besprechen.
              </div>
            </Card.Body>
          </Card>

          <Card>
            <Card.Img variant="top" src={paymentImg} />
            <Card.Body>
              <Card.Title>Rechnung per Email</Card.Title>
              <div className="h1">
                Sobal die Arbeiten erledigt sind erhalten Sie eine Rechnung per
                Email, welche Sie per <i>Überweisung</i> oder <i>PayPal</i>{" "}
                begleichen können.
              </div>
            </Card.Body>
          </Card>

          <Card>
            <Card.Img variant="top" src={returnImg} />
            <Card.Body>
              <Card.Title>Rückversand</Card.Title>
              <div className="h1">
                Nach Zahlungseingang leiten wir unverzüglich den Rückversand zu
                Ihnen ein.
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Zurück
          </Button>
        </Modal.Footer>
      </Modal>

      <Card id="versand">
        <Card.Img
          variant="top"
          src={img}
          style={{ objectPosition: "bottom" }}
        />
        <Card.Body>
          <Card.Title>Versandservice</Card.Title>
          Sie sind zeitlich eingespannt oder würden sich gerne den Fahrtweg
          sparen? Wir bieten Ihnen auch einen angenehmen Versandservice an.
          <Button
            variant="secondary"
            onClick={handleShow}
            style={{ margin: "15px auto", display: "flex" }}
          >
            Mehr Informationen
          </Button>
        </Card.Body>
      </Card>
    </>
  );
}
