import "../App.scss";
import Card from "react-bootstrap/Card";
import img from "../assets/keys.jpg";

export default function Keys() {
  return (
    <Card id="keys">
      <Card.Img variant="top" src={img} />
      <Card.Body>
        <Card.Title>Schlüssel</Card.Title>
        <Card.Text>Gerne fertigen wir Kopien ihrer Schlüssel an.</Card.Text>
      </Card.Body>
    </Card>
  );
}
