import "../App.scss";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import img from "../assets/pricing.png";

export default function Preisliste() {
  return (
    <Card id="preisliste">
      <Card.Img variant="top" src={img} style={{ objectPosition: "top" }} />
      <Card.Body>
        <Card.Title>Preisliste</Card.Title>
        <div>
          <h1>Absätze</h1>
          <h2>
            Gummi <i>9,50€ - 16,50€</i>
          </h2>
          <h2>
            Halb Leder / halb Gummi <i>21,50€</i>
          </h2>
          <h2>
            Profil <i>18,50€ - 21,50€</i>
          </h2>
          <h2>
            Ecken <i>13,50€ - 14,50€</i>
          </h2>

          <h1>Sohlen</h1>
          <h2>
            Gummi <i>18,50€ - 24,50€</i>
          </h2>
          <h2>
            Leder <i>23,50€ - 26,50€</i>
          </h2>
          <h2>
            Profil <i>45,00€</i>
          </h2>
          <h2>
            Spitzen <i>13,50€ - 14,50€</i>
          </h2>

          <h1>Sonstiges</h1>
          <h2>
            Fersenfutter <i>14,50€ - 18,50€</i>
          </h2>
          <h2>
            Reißverschlüsse bis 20cm <i>25,00€</i>
            <br />
            darüber hinaus je cm <i>1,00€</i>
          </h2>

          <h2>
            Reitstiefelreißverschluss pro Stück <i>45,00€</i>
          </h2>

          <Alert variant="warning">
            Die Preisliste dient nur zur ersten Orientierung. Der endgültige
            Preis erfolgt nach Rücksprache.
          </Alert>
        </div>
      </Card.Body>
    </Card>
  );
}
