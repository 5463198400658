//CSS
import "./App.scss";

//Components
import Navigation from "./components/Navigation/Navigation";
import Home from "./components/Home/Home";
import Location from "./components/Location";
import AboutMe from "./components/AboutMe";
import Services from "./components/Services";
import Schuhreparatur from "./components/Schuhreparatur";
import Reitsport from "./components/Reitsport";
import Lederarbeiten from "./components/Lederarbeiten";
import Keys from "./components/Keys";
import Versand from "./components/Versand";
import Produkte from "./components/Produkte";
import Belt from "./components/Belt";
import Kontakt from "./components/Kontakt/Kontakt";

function App() {
  return (
    <>
      <Navigation />

      <div className="appContainer">
        <Home />
        <Location />
        <AboutMe />
        <Versand />
        <Schuhreparatur />
        <Reitsport />
        <Lederarbeiten />
        <Belt />
        <Produkte />
        <Keys />
        <Kontakt />
      </div>
    </>
  );
}

export default App;
