import "./Kontakt.scss";

export default function Kontakt() {
  return (
    <div id="kontakt" className="imgContainer">
      <div className="dataBlock">
        <div className="dataRow">
          <div className="textContent">
            <h1>telefon</h1>
            <a href="tel:0049607432154">06074 321 54</a>
          </div>
        </div>
        <div className="dataRow">
          <div className="textContent">
            <h1>whatsapp</h1>
            <h2>0176 227 384 95</h2>
          </div>
        </div>

        <div className="dataRow">
          <div className="textContent">
            <h1>email</h1>
            <a href="mailto:info@schuhmacherei-vella.de">
              info@schuhmacherei-vella.de
            </a>
          </div>
        </div>

        <div className="dataRow">
          <div className="textContent">
            <h1>adresse</h1>
            <h2>Rathenaustraße 20</h2>
            <h2>63128 Dietzenbach</h2>
          </div>
        </div>
        <div className="dataRow">
          <div className="textContent">
            <h1>öffnungszeiten</h1>
            <h3>Dienstag - Freitag</h3>
            <h2>09:00 - 13:00 & 15:00 - 18:00</h2>
            <h3>Samstag</h3>
            <h2>09:00 - 13:00</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
