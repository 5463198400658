import "./Logo.scss";

export default function Logo() {
  return (
    <div className="logoContainer">
      <h1>Schuhmacherei</h1>
      <h2>Vella</h2>
    </div>
  );
}
