import "../App.scss";
import Card from "react-bootstrap/Card";
import img from "../assets/reitsport.jpg";

export default function Reitsport() {
  return (
    <Card id="reitstiefel">
      <Card.Img variant="top" src={img} style={{ objectPosition: "top" }} />
      <Card.Body>
        <Card.Title>Reitsportartikel</Card.Title>
        <Card.Text>
          Auch Ihre Reitsportartikel sind bei uns in guten Händen. Egal ob Ihre
          Reitstiefel oder Chaps neue Reißverschlüsse benötigen, oder diverse
          Reparaturen an Halfter, Trensen, Gamaschen, Gurtzügel anstehen, hier
          wird dies fachgerecht durchgeführt.
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
