import "../App.scss";
import Card from "react-bootstrap/Card";
import img from "../assets/carmelo.jpeg";

export default function AboutMe() {
  return (
    <Card id="aboutMe">
      <Card.Img variant="top" src={img} style={{ objectPosition: "top" }} />
      <Card.Body>
        <Card.Title>Hallo, mein Name ist Carmelo Vella!</Card.Title>
        <Card.Text>
          Bei mir sind Ihre Schuhe in den besten Händen.
          <br />
          <br /> Gerne unterbreite ich Ihnen für ihre Schuhe ein
          Reparaturangebot vor Ort bei mir im Laden. <br />
          <br />
          Alternativ biete ich Ihnen auch gerne einen Versandservice an.
          {/* Falls Sie allerdings zeitlich eingespannt sind, oder sich den Fahrt
          Weg sparen möchten, haben Sie auch die Möglichkeit unseren
          Versandservice zu nutzen. */}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
