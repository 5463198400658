import "../App.scss";
import Card from "react-bootstrap/Card";
import img from "../assets/serviceSchuh1.jpg";

export default function Schuhreparatur() {
  return (
    <Card id="schuhrepair">
      <Card.Img variant="top" src={img} />
      <Card.Body>
        <Card.Title>Schuhreparatur</Card.Title>
        <Card.Text>
          Wir führen fachgerechte Schuhreparaturen aller Art aus und finden für
          kleine und größere "Notfälle" die beste Lösung für Sie. Ob Herren-,
          Damen-, Wander- oder Turnschuhe....egal ob´s im Schuh drückt oder die
          Sohle erneuert werden muss. Wir prüfen den Gesamtzustand Ihrer Schuhe
          und legen mit Ihnen fest was repariert werden soll.
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
