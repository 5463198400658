import "../App.scss";
import Card from "react-bootstrap/Card";
import img from "../assets/lederarbeiten.jpg";

export default function Lederarbeiten() {
  return (
    <Card id="lederarbeit">
      <Card.Img variant="top" src={img} />
      <Card.Body>
        <Card.Title>Lederarbeiten</Card.Title>
        <Card.Text>
          Wir führen auch diverse Lederarbeiten für Sie durch!
          Taschenreparaturen, Erneuerung oder Reparatur von Reißverschlüssen in
          Lederjacken/Motorradjacken, sowie die Anbringung von Druckknöpfen und
          Nieten/Ösen, werden bei uns ausgeführt.
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
