import "../App.scss";
import { Card } from "react-bootstrap";
import img from "../assets/produkte.jpeg";

export default function Produkte() {
  return (
    <Card id="produkte">
      <Card.Img variant="top" src={img} />
      <Card.Body>
        <Card.Title>Schuhpflegeprodukte & more</Card.Title>
        <Card.Text>
          Benötigen Sie für Ihre Schuhe Pflegeprodukte? Wir beraten Sie hier
          gerne weiter. Rund um die Schuhpflege gibt es (fast) nichts, was Sie
          nicht bei uns finden werden: Schuh- und Lederpflegemittel, Bürsten in
          verschiedenen Größen und Härten, Schnürsenkel in verschiedenen Farben
          und Längen, Einlegesohlen in Leder, Kork oder Frottee, etc.
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
